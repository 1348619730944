:root {
  --font-Poppins: 'Poppins', sans-serif;
}

* {
  font-family: var(--font-Poppins);
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: calc(50% + 100px);
  transform: translateX(-calc(50% +100px));
}

.avatar {
  display: inline-block;
  margin-left: 5px;
  border-radius: 50%;
  max-height: 20px;
  width: auto;
}

.leaflet-editiing-icon {
  border-radius: 100% !important;
}

.video-react .video-react-big-play-button {
  display: none !important;
}

.overlay-image:hover {
  -webkit-filter: brightness(70%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
}

.video-react-poster {
  background-size: cover !important;
}

.video-react-poster:hover {
  -webkit-filter: brightness(70%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* @media screen and (min-width: 1600px) {
  .video-react {
    height: 680px;
  }
}

@media screen and (min-width: 1400px) {
  .video-react {
    height: 600px;
  }
}


@media screen and (min-width: 1024px) {
  .video-react {
    height: 500px;
  }
}

@media screen and (min-device-width: 768px)
and (max-device-width: 1024px) {
  .video-react {
    height: 400px;
  }
}  */

.video-react-poster,
.video-react,
.video-react-video {
  border-radius: 12px;
}

.pl-0 {
  padding-left: 0 !important;
}

.middle {
  vertical-align: middle;
}

.game-dashboard-tabImage {
  padding: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

a {
  text-decoration: none;
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 6px;
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 3px; /* Rounded corners for the thumb */
}

/* Style the scrollbar track (the background) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
  border-radius: 30px;
}

.react-custom-datepicker {
  width: 100%;
}

#__next {
  position: relative;
  z-index: 1;
}

#portal {
  position: relative;
  z-index: 2;
}

.Spreadsheet__header,
.Spreadsheet__cell {
  min-width: 10px !important;
  min-height: 10px !important;
  height: 20px !important;
  width: 50px !important;
}

/* MUI Select side btn dropdown static css  */
.btn-dropdown fieldset {
  border: none;
}
.btn-dropdown .MuiSelect-icon {
  color: white;
}

.tiktok-player {
  width: 800px;
  height: 60vh !important;
  background-color: hsl(80, 72%, 31%);
  padding: 200px;
}

/* .tiktok-embed {
  height: 60vh !important;
  min-height: 30vh !important;
  max-height: 60vh !important;
} */

/* Limit text to 1 line */
.clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.tbl-border {
  border-collapse: collapse;
  border: 1px solid gainsboro;
}